import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button/Button";
import { SelectInput, TextInput } from "../../components/ui/form";
import Workspace from "../../components/workspace";
import branchService from "../../services/branch-service";
import { validateBranch } from "../../utils/validateBranch";

const branchTemplate = {
   isSystemUse: "N",
   branchCode: "",
   branchName: "",
   residentPastor: "",
   phone: "",
   address: "",
   physicalAddress: "",
};

const BranchForm = ({ modal, id, setSelected = () => {} }) => {
   const [fetchedBranch, setFetchedBranch] = useState(branchTemplate);
   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();

   const heading = id ? "Edit Branch" : "Add New Branch";
   const controller = new AbortController();

   useEffect(() => {
      const loadBranchDetails = async () => {
         try {
            const fetchResult = await branchService.getBranch(id);
            setFetchedBranch(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadBranchDetails();

      return () => {
         setFetchedBranch({});
         setFetchError("");
         setIsLoading(id);
         setSubmitError(undefined);
      };
   }, [id]);

   const handleSubmit = async (branch) => {
      setSubmitError(null);

      try {
         const submitResult = await branchService.submitBranchMaintenance({
            branch,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const reset = () => {
      setFetchedBranch({});
      setSuccessNotificationText(null);
      setSubmitError(null);
      setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError) return <Workspace.FetchErrorModal modal={modal} heading={heading} message={fetchError} callback={reset} />;
   if (successNotificationText)
      return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   return (
      <Modal
         isOpen={modal.isOpen}
         onClose={() => {
            reset();
            modal.onClose();
         }}
         size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik
                           initialValues={id ? fetchedBranch : branchTemplate}
                           validate={validateBranch}
                           onSubmit={handleSubmit}
                           enableReinitialize>
                           {({ isSubmitting, values }) => (
                              <Form className="grid gap-6">
                                 <SelectInput
                                    label="Is SYSTEM branch?"
                                    name="isSystemUse"
                                    value={values?.isSystemUse || "N"}
                                    disabled={Boolean(id)}
                                    required>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                 </SelectInput>
                                 <TextInput
                                    type="text"
                                    label="Branch Code"
                                    id="branchCode"
                                    name="branchCode"
                                    placeholder={
                                       values.isSystemUse === "Y"
                                          ? "Enter branch code here. Format H<DD>, eg H01"
                                          : "Enter branch code here. Format DD, eg 01"
                                    }
                                    disabled={id}
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Branch Name"
                                    id="branchName"
                                    name="branchName"
                                    placeholder="Enter branch name here"
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Resident Pastor"
                                    id="residentPastor"
                                    name="residentPastor"
                                    placeholder="Enter branch resident pastor here"
                                 />

                                 <TextInput type="phone" label="Phone" id="phoneNo" name="phoneNo" />

                                 <TextInput type="text" label="Address" id="physicalAddress" name="physicalAddress" />

                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}

                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          modal.onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                       {!Boolean(id) ? "Create branch" : "Edit branch"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default BranchForm;
