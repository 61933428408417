import { FieldConfig, useField } from "formik";
import { ClassAttributes, InputHTMLAttributes, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";

type InputProp = ClassAttributes<HTMLInputElement> &
   InputHTMLAttributes<HTMLInputElement> &
   FieldConfig<any> & { label?: string };

type SelectProp = ClassAttributes<HTMLSelectElement> &
   InputHTMLAttributes<HTMLSelectElement> &
   FieldConfig<any> & { label?: string };

const TextInput = ({ label, ...props }: InputProp) => {
   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label && <FieldLabel htmlFor={`${props.id || props.name}`}>{label}</FieldLabel>}
         <input
            className="form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem]"
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

const FieldError = ({ children, className }) => {
   return <span className={`form-error | inline-block text-red-500 text-sm ${className || ""}`}>{children}</span>;
};

const FieldLabel = ({ children, htmlFor }) => {
   return (
      <label htmlFor={htmlFor} className="form-label | block mb-1 text-sm text-gray-600 font-bold">
         {children}
      </label>
   );
};

type TextAreaProp = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> &
   FieldConfig<any> & { label?: string };

const TextArea = ({ label, className, ...props }: TextAreaProp) => {
   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label && <FieldLabel htmlFor={`${props.id || props.name}`}>{label}</FieldLabel>}
         <textarea
            className={`form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem] ${className}`}
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

const SelectInput = ({ label, ...props }: SelectProp) => {
   if (!Boolean(props.id || props.name))
      throw new Error("Select field ID or NAME is required.", {
         cause: "Custom select component was used without providing field ID or NAME.",
      });

   const [field, meta] = useField(props);
   return (
      <div className="form-group | ">
         {label ? <FieldLabel htmlFor={props.id || props.name}>{label}</FieldLabel> : null}
         <select
            className="form-select | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-4 py-[0.7rem]"
            {...field}
            {...props}
         />
         {meta.touched && meta.error ? <FieldError className="mt-1">{meta.error}</FieldError> : null}
      </div>
   );
};

const CheckboxListItem = ({ children, ...props }: InputProp) => {
   const [field] = useField(props);

   return (
      <label className="flex w-full items-center p-2 cursor-pointer border-b first:border-t">
         <input className="me-1" type="checkbox" {...field} {...props} />
         {children}
      </label>
   );
};

const InfoTooltip = ({ children }) => {
   const [isOpen, setIsOpen] = useState(false);
   const popUp = (
      <div className="absolute w-[250px] bottom-[120%] bg-white border-[1px] border-gray-200 shadow-lg p-4 rounded-lg">
         {children}
      </div>
   );

   return (
      <div className="info-wrapper | relative">
         <button type="button" className="p-0" title={children}>
            <BiInfoCircle className="text-blue-600 text-lg" />
         </button>
         {/* {isOpen ? popUp : null} */}
      </div>
   );
};

export { CheckboxListItem, FieldError, FieldLabel, InfoTooltip, SelectInput, TextArea, TextInput };
