import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Spinner,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import Pagination from "../../components/Pagination";
import { DangerBadge, PrimaryBadge, SecondaryBadge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button/Button";
import EmptyList from "../../components/ui/EmptyList";
import { Loading } from "../../components/ui/loading";
import { ResourceLoadingError } from "../../components/ui/resource-loading-error";
import Workspace from "../../components/workspace";
import tokenService from "../../services/token-service";
import { liveEventsService } from "../liveEvents/live-events.service";

const PAGE_SUBHEADING = "Event Questions";

function LiveEventsQuestions() {
   const { eventAlias } = useParams();
   const navigate = useNavigate();

   return (
      <>
         <Workspace.TopBar heading="Live Event Questions" />
         <Workspace.WorkspaceActions>
            <Button variant="primary" onClick={() => navigate("..", { relative: "path" })}>
               <BsArrowLeft className="me-2 ms-0" /> Back
            </Button>
         </Workspace.WorkspaceActions>
         <Workspace.ComponentWrapper className="mb-4">
            {/* <SocketProvider> */}
            <EventQuestionsProvider>
               <Workspace.WorkspaceHeader>
                  <Workspace.WorkspaceHeaderWrapper>
                     <Workspace.WorkspaceSubHeading>
                        <SubHeading eventAlias={eventAlias} />
                     </Workspace.WorkspaceSubHeading>
                  </Workspace.WorkspaceHeaderWrapper>
               </Workspace.WorkspaceHeader>
               <EventQuestions eventAlias={eventAlias} />
            </EventQuestionsProvider>
            {/* </SocketProvider> */}
         </Workspace.ComponentWrapper>
      </>
   );
}

export default LiveEventsQuestions;

const SubHeading = ({ eventAlias }) => {
   const { totalQuestions } = useEventQuestions();

   return (
      <>
         {PAGE_SUBHEADING} [{eventAlias} - <small>{totalQuestions} Question(s)</small>]
      </>
   );
};

function EventQuestions({ eventAlias }) {
   const [isLoading, setIsLoading] = useState(true);
   const [errorMessage, setErrorMessage] = useState("");
   const [eventQuestions, setEventQuestions] = useState([]);
   const [page, setPage] = useState(1);
   const [rows] = useState(10);
   const [totalPages, setTotalPages] = useState(1);
   const socket = useSocket();
   const { setTotalQuestions } = useEventQuestions();

   useEffect(() => {
      async function loadEventQuestion() {
         setIsLoading(true);
         try {
            const fetchResult = await liveEventsService.getEventQuestions({ eventAlias, page, rows });

            setEventQuestions(fetchResult.data);
            setTotalPages(fetchResult.totalPages);
            setTotalQuestions(fetchResult.total);
         } catch (error) {
            setErrorMessage(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      loadEventQuestion();

      return () => {
         setErrorMessage(null);
      };
   }, [page, rows, eventAlias, setTotalQuestions]);

   useEffect(() => {
      if (socket) {
         try {
            socket.on("update-question", ({ data }) => {
               setEventQuestions((_prev) =>
                  _prev.map((_question) => {
                     if (_question.id === data.id) {
                        return Object.assign({}, _question, data);
                     }
                     return _question;
                  })
               );
            });
            socket.on("create-question", ({ data, total }) => {
               setEventQuestions((_prev) => [..._prev, data]);
               setTotalQuestions(total);
            });
         } catch (error) {}
      }
      return () => {
         socket && socket.off("update-question");
         socket && socket.off("create-question");
      };
   }, [setTotalQuestions, socket]);

   if (isLoading) return <Loading />;
   if (errorMessage) return <ResourceLoadingError error={errorMessage} />;

   return (
      <div className="pb-4 mb-3">
         <EventQuestionsList eventQuestions={eventQuestions} eventAlias={eventAlias} setEventQuestions={setEventQuestions} />
         {eventQuestions.length ? <Pagination setPage={setPage} page={page} totalPages={totalPages} /> : null}
      </div>
   );
}

function EventQuestionsList({ eventQuestions = [], eventAlias, setEventQuestions }) {
   const [selectedQuestion, setSelectedQuestion] = useState({});
   const questionDetailsModal = useDisclosure();
   if (!Boolean(eventQuestions.length)) return <EmptyList message="Events questions would be listed here" />;

   return (
      <>
         <QuestionDetails
            question={selectedQuestion}
            eventAlias={eventAlias}
            modal={questionDetailsModal}
            setEventQuestions={setEventQuestions}
         />
         <ul>
            {eventQuestions
               .sort((a, b) => (a.status > b.status ? -1 : 1))
               .map((_eventQuestion) => (
                  <EventQuestion
                     eventQuestion={_eventQuestion}
                     key={_eventQuestion.id}
                     onClick={() => {
                        setSelectedQuestion(_eventQuestion);
                        questionDetailsModal.onOpen();
                     }}
                  />
               ))}
         </ul>
      </>
   );
}

function EventQuestion({ eventQuestion, ...rest }) {
   let statusBadge;

   switch (eventQuestion.status?.toUpperCase()) {
      case "UNREAD":
         statusBadge = <PrimaryBadge>{eventQuestion.status}</PrimaryBadge>;
         break;
      case "READ":
         statusBadge = <SecondaryBadge>{eventQuestion.status}</SecondaryBadge>;
         break;
      default:
         statusBadge = <DangerBadge>{eventQuestion.status}</DangerBadge>;
         break;
   }

   return (
      <Workspace.ItemWrapper {...rest}>
         <div className="flex justify-between items-center px-4 py-5">
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold">{`${eventQuestion.name || "Anonymous"}`}</p>
               <p className="mb-2">{eventQuestion.question}</p>
            </div>

            <div className="col-3 col-md-2 col-lg-2 text-center" title="Read status">
               {statusBadge}
            </div>
         </div>
      </Workspace.ItemWrapper>
   );
}

const QuestionDetails = ({ eventAlias, question, modal, setEventQuestions }) => {
   const [marking, setMarking] = useState(null);
   const { isOpen, onClose } = modal;
   const toast = useToast();

   async function changeQuestionStatus(status) {
      setMarking(status);
      try {
         await liveEventsService.updateQuestionStatus({ eventAlias, questionId: question.id, status });
         toast.success("Event modified successfully");
         setEventQuestions((_prev) =>
            _prev.map((_question) => (question.id === _question.id ? Object.assign({}, _question, { status }) : _question))
         );
         onClose();
      } catch (error) {
         toast.error(error.message);
      } finally {
         setMarking(null);
      }
   }

   return (
      <AlertDialog isOpen={isOpen} onClose={onClose} size="2xl">
         <AlertDialogOverlay />
         <AlertDialogContent className="!rounded-2xl p-4 mx-4">
            <AlertDialogHeader>Question</AlertDialogHeader>
            <AlertDialogBody className="text-center my-8">
               <div className="question-details | mb-4">
                  <p className="event | text-2xl">#{eventAlias}</p>
                  <p>
                     Sender: <span className="font-semibold">{question.name || "Anonymous"}</span>
                  </p>
               </div>
               <p className="question | text-left">{question.question}</p>
            </AlertDialogBody>

            <AlertDialogFooter className="!block">
               <div className="grid md:justify-between md:grid-flow-col gap-y-10 gap-x-4">
                  <div className="grid md:grid-flow-col  gap-2 md:justify-start">
                     {question.status?.toUpperCase() === "UNREAD" ? (
                        <Button variant="secondary" onClick={() => changeQuestionStatus("READ")}>
                           Mark as read{" "}
                           {marking === "READ" ? (
                              <span className="ms-4">
                                 <Spinner />
                              </span>
                           ) : null}
                        </Button>
                     ) : null}
                     {question.status?.toUpperCase() === "READ" ? (
                        <Button variant="success" onClick={() => changeQuestionStatus("UNREAD")}>
                           Mark as unread{" "}
                           {marking === "UNREAD" ? (
                              <span className="ms-4">
                                 <Spinner />
                              </span>
                           ) : null}
                        </Button>
                     ) : null}
                     {question.status?.toUpperCase() !== "IGNORED" ? (
                        <Button variant="danger" onClick={() => changeQuestionStatus("IGNORED")}>
                           Mark as ignored{" "}
                           {marking === "IGNORED" ? (
                              <span className="ms-4">
                                 <Spinner />
                              </span>
                           ) : null}
                        </Button>
                     ) : null}
                  </div>
                  <div className="grid gap-2 md:justify-end">
                     <Button variant="primary" onClick={onClose}>
                        Close
                     </Button>
                  </div>
               </div>
            </AlertDialogFooter>
         </AlertDialogContent>
      </AlertDialog>
   );
};

const SocketContext = createContext();

function SocketProvider({ children }) {
   const [socket, setSocket] = useState();

   const newSocket = io("http://localhost:3001/", {
      withCredentials: true,
      auth: { csrf: tokenService.getAccessToken() },
   });
   const token = tokenService.getAccessToken();

   useEffect(() => {
      const newSocket = io("http://localhost:3001/", {
         withCredentials: true,
         auth: { csrf: token },
      });
      setSocket(newSocket);

      return () => newSocket.close();
   }, [token]);

   return <SocketContext.Provider value={newSocket}>{children}</SocketContext.Provider>;
}

function useSocket() {
   return useContext(SocketContext);
}

const EventQuestionsContext = createContext();

const EventQuestionsProvider = ({ children }) => {
   const [totalQuestions, setTotalQuestions] = useState(0);

   return (
      <EventQuestionsContext.Provider value={{ totalQuestions, setTotalQuestions }}>{children}</EventQuestionsContext.Provider>
   );
};

const useEventQuestions = () => {
   return useContext(EventQuestionsContext);
};
