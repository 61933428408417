import { useDisclosure } from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";
import { BsArrowRightCircle, BsGrid } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import authService from "../../services/auth-service";
import { useBranches } from "../../state/branchListContext";
import "../../styles/sidebar.css";
import { MenuLink } from "../../types";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import { RequirementGuard } from "../ComponentRequireRole";
import { NavItemWithIcon } from "../NavItem";
import { Button } from "../ui/button/Button";
import SidebarUser from "./SidebarUser";
import { LogoutDialog } from "./logout-dialog";
import { SidebarBranch } from "./sidebar-branch";

const Sidebar = () => {
   const { getUserInfo } = authService;
   const menuLinks: MenuLink[] = authService.getUserInfo()?.menuLinks || [];
   const { setRequestBranchAsCookie, branches, userCanSwitchBranch } = useBranches();
   const logoutModal = useDisclosure();
   const location = useLocation();

   const ACTIVE_SUBMENU_CLASS_NAME = "nav__link-subLinks--active";
   function hideSidebar() {
      document.querySelector(".sidebar__content")?.classList.remove("sidebar-active");
      document.querySelector(".overlay")?.classList.remove("sidebar-active");
   }

   return (
      <>
         <LogoutDialog modal={logoutModal} />

         <div className="overlay" onClick={hideSidebar}></div>
         <div className="sidebar | h-full">
            <div className="sidebar__content | flex flex-col gap-4 bg-white py-4 min-w-[280px] overflow-y-scroll">
               <div className="sidebar__brand | w-[80px] mt-4 mx-auto mb-6">
                  <img src={process.env.PUBLIC_URL + "/hwa_logo.png"} alt="" />
               </div>
               <RequirementGuard condition={userCanSwitchBranch()}>
                  <SidebarBranch
                     branches={branches}
                     requestBranch={authService.getRequestBranchFromCookie()}
                     changeRequestBranch={setRequestBranchAsCookie}
                  />
               </RequirementGuard>

               <SidebarUser
                  user={(function () {
                     const user = getUserInfo();
                     user.branch = branches.find((_branch) => _branch.branchCode === user.branchCode);
                     return user;
                  })()}
               />
               <div id="nav-links" className="h-full grid mb-8">
                  <nav className="nav | my-3 mb-16">
                     <ul>
                        <li className="nav__item | mb-1">
                           <NavItemWithIcon onClick={hideSidebar} to="/dashboard">
                              <BsGrid className="me-4 text-xl" />
                              Dashboard
                           </NavItemWithIcon>
                        </li>

                        {/* <li className="nav__item | mb-1">
                        <NavItemWithIcon onClick={hideSidebar} to="/live-events">
                           <MdOutlineLiveTv className="me-4 text-xl" />
                           Live Events
                        </NavItemWithIcon>
                     </li> */}
                        {menuLinks?.length
                           ? sortRecordsByObjectProp(menuLinks, "name").map((link, key) =>
                                link.subLinks ? (
                                   <li
                                      className="nav__item | mb-1 has-subLinks"
                                      key={key}
                                      onClick={(e) => {
                                         e.preventDefault();
                                         e.stopPropagation();

                                         // remove dropdown class from other submenu links
                                         const currentActiveSubmenuNode = document.querySelector("." + ACTIVE_SUBMENU_CLASS_NAME);

                                         currentActiveSubmenuNode &&
                                            currentActiveSubmenuNode !== e.currentTarget &&
                                            currentActiveSubmenuNode.classList.remove(ACTIVE_SUBMENU_CLASS_NAME);

                                         e.currentTarget.classList.add(ACTIVE_SUBMENU_CLASS_NAME);
                                      }}>
                                      <a
                                         href="/#"
                                         className={`nav__link | flex items-center p-3 rounded-lg ${
                                            link.subLinks.flatMap((_subLink) => _subLink.to).includes(location.pathname)
                                               ? "text-primary bg-bgPrimaryLight nav__link-active"
                                               : ""
                                         }`}
                                         role="button">
                                         <div className="flex justify-between items-center w-full">
                                            <i className={link.icon + " me-4 text-xl"} />
                                            <span className="w-full">{link.name} </span>
                                            <BsArrowRightCircle className="text-lg" />
                                         </div>
                                      </a>
                                      <SubLinks subLinks={link.subLinks} onClick={hideSidebar} />
                                   </li>
                                ) : (
                                   <li
                                      className="nav__item | mb-1"
                                      key={key}
                                      onClick={(e) => {
                                         // remove dropdown class from submenu links
                                         const currentActiveSubmenuNode = document.querySelector("." + ACTIVE_SUBMENU_CLASS_NAME);

                                         currentActiveSubmenuNode &&
                                            currentActiveSubmenuNode.classList.remove(ACTIVE_SUBMENU_CLASS_NAME);
                                      }}>
                                      <NavItemWithIcon onClick={hideSidebar} to={link.to}>
                                         <i className={link.icon}></i>
                                         {link.name}
                                      </NavItemWithIcon>
                                   </li>
                                )
                             )
                           : null}
                     </ul>
                  </nav>

                  <div className="grid self-end">
                     <Button variant="secondary" onClick={logoutModal.onOpen}>
                        <div className="flex items-center gap-2 w-full -ms-6">
                           <BiLogOut className="text-2xl rotate-180" />
                           Log Out
                        </div>
                     </Button>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

const SubLinks = ({ subLinks, onClick }: { subLinks: MenuLink[]; onClick: Function }) => {
   return (
      <div className="sub-links | my-1" id="subLinks">
         {
            <ul>
               {sortRecordsByObjectProp(subLinks, "name").map((_link, key) => (
                  <li className="nav__item | mb-1" key={key}>
                     <NavItemWithIcon onClick={onClick} to={_link.to}>
                        <i className={_link.icon}></i>
                        {_link.name}
                     </NavItemWithIcon>
                  </li>
               ))}
            </ul>
         }
      </div>
   );
};

export default Sidebar;
