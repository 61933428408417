import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button/Button";
import { SelectInput, TextInput } from "../../components/ui/form";
import Workspace from "../../components/workspace";
import attendanceService from "../../services/attendance-service";
import authService from "../../services/auth-service";
import { useBranches } from "../../state/branchListContext";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import validateAttendance from "../../utils/validateAttendance";

const formatChildrenInputField = ({ values, toServer }) => {
   return toServer
      ? Object.assign({}, values, { children: values.childrenn })
      : Object.assign({}, values, { childrenn: values.children });
};

const AttendanceForm = ({ modal, id }) => {
   const defaultYear = new Intl.DateTimeFormat("en", { year: "numeric" }).format(new Date());
   const defaultMonth = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(new Date());
   const defaultDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(new Date());
   const defaultDate = `${defaultYear}-${defaultMonth}-${defaultDay}`;

   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState(null);
   const [submitError, setSubmitError] = useState(null);
   const { branches, userCanSwitchBranch } = useBranches();
   const { branchCode } = authService.getUserInfo();
   const [attendance, setAttendance] = useState({
      branchCode,
      children: "",
      attendanceDate: defaultDate,
   });
   const { isOpen, onClose } = modal;

   const heading = Boolean(id) ? "Edit Attendance Record" : "Record Attendance";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadAttendanceDetails() {
         try {
            const fetchResult = await attendanceService.getAttendance(id);
            setAttendance(formatChildrenInputField({ values: fetchResult.data }));
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      id && loadAttendanceDetails();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   const handleSubmit = async (attendance) => {
      let submitResult;
      attendance = formatChildrenInputField({ values: attendance, toServer: true });
      try {
         if (id) {
            submitResult = await attendanceService.submitAttendanceMaintenance({
               attendance,
               editOrCreate: "EDIT",
               signal,
            });
         } else {
            submitResult = await attendanceService.submitAttendanceMaintenance({ attendance, signal });
         }
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   if (isLoading) return <Workspace.LoadingModal modal={modal} controller={controller} heading={heading} />;

   if (successNotificationText) return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} />;

   if (fetchError) return <Workspace.FetchErrorModal modal={modal} message={fetchError} heading={heading} />;

   return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full ">
                        <Formik
                           initialValues={attendance}
                           validate={validateAttendance}
                           onSubmit={handleSubmit}
                           enableReinitialize>
                           {({ isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <SelectInput
                                    label="Attendance Branch"
                                    id="branchCode"
                                    name="branchCode"
                                    disabled={!Boolean(userCanSwitchBranch()) && Boolean(id)}
                                    required>
                                    <option value="">Select branch</option>
                                    {branches &&
                                       sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
                                          <option className="py-4" value={_branch.branchCode} key={key}>
                                             {`${_branch.branchCode} - ${_branch.branchName}`}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 <TextInput
                                    type="date"
                                    label="Attendance Date"
                                    id="attendanceDate"
                                    name="attendanceDate"
                                    required
                                 />
                                 <TextInput type="text" label="Service name" id="serviceName" name="serviceName" required />
                                 <TextInput type="number" label="Male" id="male" name="male" required />
                                 <TextInput type="number" label="Female" id="female" name="female" required />
                                 <TextInput type="number" label="Children" id="childrenn" name="childrenn" required />
                                 <TextInput type="number" label="Salvation" id="salvation" name="salvation" />
                                 <TextInput
                                    type="number"
                                    label="Holy Spirit Baptism"
                                    id="holySpiritBaptism"
                                    name="holySpiritBaptism"
                                 />
                                 <TextInput type="number" label="Testimonies" id="testimony" name="testimony" />
                                 <TextInput type="number" label="Prophesies" id="prophesy" name="prophesy" />
                                 <TextInput type="number" label="First Timer" id="firstTimer" name="firstTimer" />
                                 <TextInput type="number" label="Number of Vehicles" id="vehicle" name="vehicle" />
                                 <TextInput type="text" label="Note" id="note" name="note" placeholder="Additional note" />
                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                       {id ? "Edit" : "Record Attendance"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export { AttendanceForm };
