import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button/Button";
import { FieldLabel } from "../../components/ui/form";
import { FormSubmitError } from "../../components/ui/form/submit-error";
import authService from "../../services/auth-service";
import tokenService from "../../services/token-service";

const LoginPage = () => {
   const [formValues, setFormValues] = useState({
      email: "",
      password: "",
   });
   const [showPassword, setShowPassword] = useState(false);
   const [messages, setMessages] = useState({
      successMessage: "",
      errorMessage: "",
   });
   const [isFetching, setIsFetching] = useState(false);

   const navigate = useNavigate();
   const location = useLocation();
   const from = location.state?.from?.pathname || "/dashboard";

   const handleChange = ({ target }) => {
      setFormValues(Object.assign({}, formValues, { [target.name]: target.value }));
   };

   const handleLogin = async (event) => {
      event.preventDefault();
      setIsFetching(true);
      setMessages({ errorMessage: "" });

      try {
         const loginResult = await authService.login({ ...formValues });
         setMessages({ successMessage: loginResult.message });
         tokenService.setAccessToken(loginResult.data.accessToken);
         const { branchCode } = authService.getUserInfo();
         authService.setRequestBranchAsCookie(branchCode);
         setTimeout(() => {
            navigate(from, { replace: true });
         }, 2000);
      } catch (error) {
         setMessages({ errorMessage: error.message });
      } finally {
         setIsFetching(false);
      }
   };
   const { successMessage, errorMessage } = messages;
   return (
      <div
         className="login flex justify-center items-center bg-center bg-fixed bg-no-repeat bg-cover"
         style={{
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(2, 149, 216, 0.9), rgba(2, 149, 216, 0.9)), url('${process.env.PUBLIC_URL}/login_bg.jpg')`,
         }}>
         {tokenService.getAccessToken() && <Navigate to="/dashboard" replace />}
         <div className="bg-white p-6 pb-12 rounded-2xl relative w-[90%] mx-auto md:w-[41%] lg:w-2/6">
            <div className="absolute w-full -top-12 left-0">
               <div className="p-1 w-24 aspect-square mx-auto rounded-full border border-[#f8f8f8] bg-white">
                  <img src={`${process.env.PUBLIC_URL}/hwa_logo.png`} alt="Logo" />
               </div>
            </div>
            <header className="mt-3 mb-4">
               <h1 className="text-center my-8 text-2xl font-bold">Log In</h1>
            </header>
            <form className="relative px-2 md:px-4" onSubmit={handleLogin}>
               {errorMessage ? <FormSubmitError>{errorMessage}</FormSubmitError> : null}
               {successMessage ? <p className="message message-success">{successMessage}</p> : null}
               <div className="mb-4">
                  <FieldLabel htmlFor="email">Email Address</FieldLabel>
                  <input
                     id="email"
                     className="text-[0.8rem] w-full px-3 py-4 border border-[#ced4da] rounded overflow-hidden"
                     name="email"
                     value={formValues.email}
                     placeholder="someone@example.com"
                     onChange={handleChange}
                  />
               </div>
               <div className="mb-4">
                  <FieldLabel htmlFor="password">Password</FieldLabel>
                  <div className="flex border border-[#ced4da] rounded overflow-hidden">
                     <input
                        id="password"
                        className="text-[0.8rem] w-full px-3 py-4 appearance-none"
                        name="password"
                        type={`${showPassword ? "text" : "password"}`}
                        value={formValues.password}
                        placeholder="Password"
                        onChange={handleChange}
                     />
                     <button type="button" className="px-4" onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <BsEye /> : <BsEyeSlash />}
                     </button>
                  </div>
               </div>
               <div className="grid">
                  <Button variant="primary" className="!py-4" disabled={isFetching}>
                     {isFetching ? "Authenticating" : "Log in"}
                  </Button>
               </div>
            </form>
            <div className="mt-4 text-center">
               <Link to="/forgot-password">
                  <small className="font-semibold">Forgot password?</small>
               </Link>
            </div>
         </div>
      </div>
   );
};

export default LoginPage;
