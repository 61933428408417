import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button/Button";
import { FieldLabel, SelectInput, TextInput } from "../../components/ui/form";
import Workspace from "../../components/workspace";
import authService from "../../services/auth-service";
import serviceService from "../../services/service-service";
import { useBranches } from "../../state/branchListContext";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import validateService from "../../utils/validateService";

const emptyActivity = { name: "", timeSlot: "", minister: "" };

const ServiceForm = ({ modal, id, setSelected = () => {} }) => {
   const { branchCode } = authService.getUserInfo();
   const { branches, userCanSwitchBranch } = useBranches();
   const [service, setService] = useState({
      branchCode,
      serviceDate: new Date().toJSON().slice(0, 10),
      plan: [emptyActivity],
   });

   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();

   const heading = id ? "Edit Service Record" : "Add New Service Record";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadServiceDetails() {
         try {
            const fetchResult = await serviceService.getService(id);
            setService(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      }
      id && loadServiceDetails();

      return () => {
         setFetchError("");
         setIsLoading(id);
         setSubmitError("");
      };
   }, [id]);

   const handleSubmit = async (service) => {
      setSubmitError(null);

      try {
         const submitResult = await serviceService.submitServiceMaintenance({
            service,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };
   const reset = () => {
      setSuccessNotificationText(null);
      setService({
         branchCode,
         serviceDate: new Date().toJSON().slice(0, 10),
         plan: [emptyActivity],
      });
      setSubmitError("");
      setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError)
      return <Workspace.FetchErrorModal modal={modal} heading={heading} errorMessage={fetchError} callback={reset} />;
   if (successNotificationText)
      return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   return (
      <Modal
         isOpen={modal.isOpen}
         onClose={() => {
            reset();
            modal.onClose();
         }}
         size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik initialValues={service} validate={validateService} onSubmit={handleSubmit} enableReinitialize>
                           {({ values, setFieldValue, isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <SelectInput
                                    label="Branch"
                                    id="branchCode"
                                    name="branchCode"
                                    required
                                    disabled={!Boolean(userCanSwitchBranch())}>
                                    <option value=" ">Select branch</option>
                                    {branches &&
                                       sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
                                          <option value={_branch.branchCode} key={key}>
                                             {`${_branch.branchCode} - ${_branch.branchName}`}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 <TextInput type="date" label="Date" id="serviceDate" name="serviceDate" required />
                                 <fieldset>
                                    <legend className="form-label">
                                       <FieldLabel>Service Plan:</FieldLabel>
                                    </legend>
                                    <div className="grid gap-4 ps-3 py-2" style={{ borderLeft: "5px solid #eee" }}>
                                       {values.plan?.length
                                          ? values.plan.map((activity, index) => {
                                               return (
                                                  <div key={index} className="grid gap-5">
                                                     <div className="flex flex-col md:flex-row gap-y-2 gap-x-2 mb-2">
                                                        <div className="w-full md:w-1/2">
                                                           <TextInput
                                                              type="text"
                                                              name={`plan[${index}].name`}
                                                              id={`plan[${index}].name`}
                                                              placeholder={`${index + 1} | Activity name`}
                                                              required
                                                           />
                                                        </div>
                                                        <div className=" w-full md:w-1/5">
                                                           <TextInput
                                                              type="text"
                                                              placeholder="Activity duration"
                                                              id={`plan[${index}].timeSlot`}
                                                              name={`plan[${index}].timeSlot`}
                                                              required
                                                           />
                                                        </div>
                                                        <div className=" w-full md:w-[30%]">
                                                           <TextInput
                                                              type="text"
                                                              placeholder="Minister"
                                                              name={`plan[${index}].minister`}
                                                              id={`plan[${index}].minister`}
                                                              required
                                                           />
                                                        </div>
                                                     </div>
                                                  </div>
                                               );
                                            })
                                          : null}

                                       <Button
                                          variant="secondary"
                                          disabled={
                                             values.plan?.length &&
                                             (!values.plan[values.plan?.length - 1].name ||
                                                !values.plan[values.plan?.length - 1].timeSlot ||
                                                !values.plan[values.plan?.length - 1].minister)
                                          }
                                          onClick={() => {
                                             setFieldValue(`plan[${values.plan.length}]`, emptyActivity);
                                          }}>
                                          Add activity
                                       </Button>
                                    </div>
                                 </fieldset>
                                 <TextInput type="text" label="Service name" id="name" name="name" required />
                                 <TextInput type="text" label="Sermon title" id="sermonTitle" name="sermonTitle" required />
                                 <TextInput type="time" label="Start time" id="startTime" name="startTime" required />
                                 <TextInput type="time" label="Stop time" id="stopTime" name="stopTime" required />

                                 <TextInput type="text" label="Note" id="note" name="note" placeholder="Additional note" />
                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          modal.onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                       {!Boolean(id) ? "Create service record" : "Edit service record"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default ServiceForm;
