import { Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { SelectInput, TextInput } from "../../../components/ui/form";
import Workspace from "../../../components/workspace";
import authService from "../../../services/auth-service";
import branchService from "../../../services/branch-service";
import roleService from "../../../services/role-service";
import userService from "../../../services/user-service";
import { sortRecordsByObjectProp } from "../../../utils/stripEmptyObjectProp";
import validateUser from "../../../utils/validateUser";

const UserForm = ({ modal, id, setSelectedUser = () => {} }) => {
   const user = authService.getUserInfo();

   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();
   const [fetchedUser, setFetchedUser] = useState({ branchCode: user.branchCode });
   const [allRoles, setAllRoles] = useState([]);
   const [branches, setBranches] = useState([]);
   const toast = useToast();

   const heading = id ? "Edit User" : "Add New User";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadRoles() {
         try {
            const fetchResult = await roleService.getRoles({ findAll: true });
            setAllRoles(fetchResult.data);
         } catch (error) {
            toast({ status: "error", description: error.message });
         }
      }

      loadRoles();

      return () => setAllRoles([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const loadAllBranches = async () => {
         try {
            const fetchResult = await branchService.getBranches({ includeSystemBranches: true });
            setBranches(fetchResult.data);
         } catch (error) {
            toast({ status: "error", description: error.message });
         }
      };

      loadAllBranches();

      return () => setBranches([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const loadUserDetails = async () => {
         try {
            const fetchResult = await userService.getUser({ id });
            setFetchedUser(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadUserDetails();
   }, [id]);

   const handleSubmit = async (formValues) => {
      setSubmitError(null);
      try {
         const submitResult = await userService.submitUserMaintenance({
            user: formValues,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   if (isLoading) return <Workspace.LoadingModal />;
   if (fetchError) return <Workspace.FetchErrorModal modal={modal} errorMessage={fetchError} />;
   if (successNotificationText)
      return (
         <Workspace.SuccessNotificationModal
            modal={modal}
            message={successNotificationText}
            callback={() => {
               setSuccessNotificationText(null);
               setFetchedUser({ branchCode: user.branchCode });
               setSubmitError(null);
               setSelectedUser({});
            }}
         />
      );

   return (
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik initialValues={fetchedUser} validate={validateUser} onSubmit={handleSubmit} enableReinitialize>
                           {({ isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <SelectInput label="Branch" id="branchCode" name="branchCode" required>
                                    <option value="">Select branch</option>
                                    {branches &&
                                       sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
                                          <option value={_branch.branchCode} key={key}>
                                             {`${_branch.branchCode} - ${_branch.branchName}`}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 <TextInput
                                    label="First Name"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    placeholder="Enter first name here"
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Enter last name here"
                                    required
                                 />
                                 <TextInput
                                    type="email"
                                    label="Email"
                                    id="email"
                                    name="email"
                                    placeholder="someone@example.com"
                                    required
                                 />

                                 <SelectInput label="Role" id="roleId" name="roleId" required>
                                    <option value="">Select Role</option>
                                    {allRoles &&
                                       allRoles.map((_role, key) => (
                                          <option value={_role.id} key={key}>
                                             {_role.name}
                                          </option>
                                       ))}
                                 </SelectInput>
                                 {!Boolean(id) ? null : (
                                    <SelectInput label="Status" id="status" name="status">
                                       <option value="E">Enabled</option>
                                       <option value="D">Disabled</option>
                                       <option value="L">Locked</option>
                                    </SelectInput>
                                 )}

                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          modal.onClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                       {id ? "Edit user" : "Create user"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export default UserForm;
