import { User, UserValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateEmail from "./validators/validateEmail";
import validateName from "./validators/validateName";
import validateRole from "./validators/validateRole";

export default function validateUser(user: User) {
   let errors = {} as UserValidationError;
   errors.firstName = validateName(user.firstName, "First name");
   errors.lastName = validateName(user.lastName, "Last name");
   errors.email = validateEmail(user.email);
   errors.roleId = validateRole(user.roleId);

   if (user.status && !["E", "D"].includes(user.status)) errors.status = "Status flag not allowed";
   if (!user.branchCode) errors.branchCode = "Branch code is required";

   return stripEmptyObjectProp(errors);
}
