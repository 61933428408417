import { Form, Formik } from "formik";
import { useState } from "react";
import { BiCommentEdit } from "react-icons/bi";
import { endpoints } from "../../config/endpoints";
import { axiosPrivateFetch } from "../../services/fetch-service";
import { Button } from "../ui/button/Button";
import { TextArea, TextInput } from "../ui/form";
import Workspace from "../workspace";

const FeedbackForm = () => {
   const [isOpen, setIsOpen] = useState(false);
   const [submitError, setSubmitError] = useState(null);
   const [submitMessage, setSubmitMessage] = useState(null);

   const validateFeedback = (values) => {
      let errors: { subject: string; message: string };

      if (!values.subject) errors.subject = "Feedback subject is required";
      if (!values.message) errors.message = "Feedback message is required";

      return errors;
   };

   const handleSubmit = async (formValues) => {
      setSubmitError(null);
      try {
         const submitResult = (await axiosPrivateFetch(`${endpoints.feedback}`, { method: "POST", data: formValues })) as {
            message: string;
         };

         setSubmitMessage(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   return (
      <div className="fixed bottom-4 right-4">
         <div className="relative">
            <button
               className="flex items-center justify-center rounded-full w-16 aspect-square bg-white shadow-2xl"
               type="button"
               onClick={() => setIsOpen(!isOpen)}
               title="Send feedback/Report issue">
               <BiCommentEdit className="text-2xl" />
            </button>
            {isOpen ? (
               <div className="absolute bottom-[120%] overflow-hidden bg-white shadow-xl rounded-2xl w-[400px] right-0 border border-gray-300">
                  <header className="header | bg-primary p-4 border-b-[2px]">
                     <h1 className="font-semibold text-lg text-white">Feedback Form</h1>
                  </header>
                  <div className="body | p-8">
                     {submitMessage ? (
                        <div className="flex flex-col gap-y-4 p-4 justify-center text-center">
                           Thank you for providing feedback. The support team will be notified.
                           <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                 setSubmitMessage(null);
                                 setIsOpen(!isOpen);
                              }}>
                              Close
                           </Button>
                        </div>
                     ) : (
                        <Formik initialValues={{}} validate={validateFeedback} onSubmit={handleSubmit}>
                           {({ isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <TextInput label="Subject" id="subject" name="subject" type="text" required />

                                 <TextArea
                                    label="Message"
                                    className="text-[0.8rem] w-full px-3 py-[0.5rem] border border-[#ced4da] rounded overflow-hidden resize-none"
                                    id="message"
                                    name="message"
                                    rows={5}
                                    required
                                 />

                                 {submitError ? (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 ) : null}
                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button type="reset" variant="secondary" onClick={() => setIsOpen(!isOpen)}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                       {isSubmitting ? "Please wait..." : "Submit"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     )}
                  </div>
               </div>
            ) : null}
         </div>
      </div>
   );
};

export { FeedbackForm };
