import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import EmptyList from "../../components/ui/EmptyList";
import Branch from "./branch";
import BranchForm from "./branch-form";

function Branches({ branches = [], setBranches }) {
   const [selected, setSelected] = useState(null);
   const formModal = useDisclosure();

   if (!branches.length) return <EmptyList message="Branches would be listed here" />;
   return (
      <ul>
         <BranchForm id={selected?.id} modal={formModal} setSelected={setSelected} />
         {branches.map((_branch, key) => (
            <Branch branch={_branch} key={key} setSelected={setSelected} formModal={formModal} />
         ))}
      </ul>
   );
}

export default Branches;
