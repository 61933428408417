import { isInt } from "validator";
import { Member, MemberValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateAddress from "./validators/validateAddress";
import { validateBranchCode } from "./validators/validateBranchCode";
import validateDate from "./validators/validateDate";
import validateEmail from "./validators/validateEmail";
import validateEntityDescription from "./validators/validateEntityDescription";
import { validateFullName } from "./validators/validateFullName";
import validateGender from "./validators/validateGender";
import validateMaritalStatus from "./validators/validateMaritalStatus";
import validateName from "./validators/validateName";
import validateOccupation from "./validators/validateOccupation";
import { validatePhoneNumber } from "./validators/validatePhoneNumber";

const validateMembership = (member: Member) => {
   const errors = {} as MemberValidationError;
   errors.branchCode = validateBranchCode(member.branchCode);
   errors.firstName = validateName(member.firstName, "First name");
   errors.lastName = validateName(member.lastName, "Last name");
   errors.email = validateEmail(member.email, { optional: true });
   errors.maritalStatus = validateMaritalStatus(member.maritalStatus, { optional: true });
   errors.dob = validateDate(member.dob, { optional: true });
   errors.membershipDate = validateDate(member.membershipDate, { optional: true });
   errors.weddingAnniversary = validateDate(member.weddingAnniversary, { optional: true });
   errors.firstTimeDate = validateDate(member.firstTimeDate, { optional: true });
   errors.newConvertConversionDate = validateDate(member.newConvertConversionDate, {
      optional: true,
   });
   errors.attendeeFirstDate = validateDate(member.attendeeFirstDate, { optional: true });
   errors.firstService = validateEntityDescription(member.firstService, {
      optional: true,
      entity: "First service/event",
   });
   errors.conversionEvent = validateEntityDescription(member.conversionEvent, {
      optional: true,
      entity: "Conversion event",
   });
   errors.attendeeFirstService = validateEntityDescription(member.attendeeFirstService, {
      optional: true,
      entity: "Service attended",
   });
   errors.spouseName = validateFullName(member.spouseName, { optional: true, entity: "Spouse" });
   errors.occupation = validateOccupation(member.occupation, { optional: true });
   errors.address = validateAddress(member.address, { optional: true });
   errors.phoneNo = validatePhoneNumber(member.phoneNo, { optional: true });
   errors.gender = validateGender(member.gender, { optional: true });
   errors.guardianParentName = validateFullName(member.guardianParentName, {
      optional: true,
      entity: "Guardian/parent",
   });
   errors.guardianParentPhone = validatePhoneNumber(member.guardianParentPhone, { optional: true });

   if (!isInt(String(member.groupId))) errors.membershipLevel = "Invalid membership level group";

   return stripEmptyObjectProp(errors);
};

export { validateMembership };
