import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { FieldLabel } from "../../../components/ui/form";
import Workspace from "../../../components/workspace";
import permissionService from "../../../services/permission-service";
import roleService from "../../../services/role-service";
import validateRoleForm from "../../../utils/validateRoleForm";

const RoleForm = ({ modal, id, setSelectedRole = () => {} }) => {
   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState();
   const [submitError, setSubmitError] = useState();
   const [formValues, setFormValues] = useState({ permissions: [] });
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [formErrors, setFormErrors] = useState({});
   const [allPermissions, setAllPermissions] = useState([]);

   const heading = id ? "Edit Role" : "Create New Role";
   const { isOpen, onClose } = modal;

   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      async function loadPermissions() {
         try {
            const fetchResult = await permissionService.getPermissions({ findAll: true });
            setAllPermissions(fetchResult.data);
         } catch (error) {}
      }
      loadPermissions();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const loadRoleDetails = async () => {
         try {
            const fetchResult = await roleService.getRole({ id });
            setFormValues(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadRoleDetails();

      return () => {
         setFormValues((prev) => ({ ...prev, permissions: [] }));
      };
   }, [id]);

   const handleChange = ({ target }) => {
      setFormValues(Object.assign({}, formValues, { [target.name]: target.value }));

      const fieldValidationResult = validateRoleForm({
         [target.name]: target.value,
      });

      if (fieldValidationResult.hasOwnProperty(target.name)) {
         const errorMsg = fieldValidationResult[target.name];
         setFormErrors(Object.assign({}, formErrors, { [target.name]: errorMsg }));
      } else {
         setFormErrors((prev) => delete prev[target.name]);
      }
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitting(true);

      const formErrors = validateRoleForm(formValues);

      try {
         if (formErrors.keys?.length) {
            setFormErrors(formErrors);
            return;
         }
         const submitResult = await roleService.submitRoleMaintenance({
            role: formValues,
            editOrCreate: id ? "edit" : "create",
         });
         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      } finally {
         setIsSubmitting(false);
      }
   };

   const removeFromRolePermissions = (_pID) => {
      const newRolePermissions = formValues.permissions.filter((_p) => _p !== _pID);
      setFormValues(
         Object.assign({}, formValues, {
            permissions: newRolePermissions,
         })
      );
   };

   const addToRolePermissions = (_pID) => {
      const newRolePermissions = [...formValues.permissions, _pID];

      setFormValues(Object.assign({}, formValues, { permissions: newRolePermissions }));
   };

   if (isLoading) return <Workspace.LoadingModal modal={modal} controller={controller} heading={heading} />;

   if (fetchError) return <Workspace.FetchErrorModal modal={modal} message={fetchError} heading={heading} />;

   if (successNotificationText)
      return (
         <Workspace.SuccessNotificationModal
            modal={modal}
            message={successNotificationText}
            callback={() => {
               setSuccessNotificationText(null);
               setFormValues({ permissions: [] });
               setSubmitError(null);
               setSelectedRole({});
            }}
         />
      );

   return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full ">
                        <form onSubmit={handleSubmit}>
                           <div className="mb-4">
                              <FieldLabel htmlFor="roleName">Role name</FieldLabel>
                              <input
                                 className="form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-3 py-[0.5rem]"
                                 id="roleName"
                                 type="text"
                                 name="name"
                                 placeholder="Enter role name"
                                 value={formValues.name}
                                 onChange={handleChange}
                                 required
                              />

                              {formErrors.name && <small className="form-error mt-1">{formErrors.name}</small>}
                           </div>
                           <div className="mb-4">
                              <FieldLabel htmlFor="roleDescription">Role description</FieldLabel>
                              <input
                                 className="form-control | border border-[#ced4da] rounded overflow-hidden text-[0.8rem] w-full px-3 py-[0.5rem]"
                                 id="roleDescription"
                                 type="text"
                                 name="description"
                                 placeholder="Enter role description"
                                 value={formValues.description}
                                 onChange={handleChange}
                                 required
                              />
                              {formErrors.description && <small className="form-error mt-1">{formErrors.description}</small>}
                           </div>

                           <FieldLabel htmlFor="RolePermission">Selected role permission</FieldLabel>
                           <div className="border border-[#ced4da] rounded-md min-h-[4rem] role-permissions flex flex-wrap p-2 mb-4 ">
                              {formValues?.permissions &&
                                 formValues.permissions.map((_permission) => {
                                    const permission = {
                                       id: _permission,
                                       name: allPermissions.find((_p) => _p.id === _permission).name,
                                    };
                                    return (
                                       <PermissionCapsule
                                          permission={permission}
                                          onChange={removeFromRolePermissions}
                                          checked={true}
                                          key={permission.id}
                                       />
                                    );
                                 })}
                           </div>
                           <FieldLabel htmlFor="allPermissions">All permissions</FieldLabel>
                           <div className="border border-[#ced4da] rounded-md min-h-[4rem] flex flex-wrap p-2 mb-4">
                              {allPermissions &&
                                 allPermissions
                                    .filter((_p) => {
                                       return !formValues?.permissions?.includes(_p.id);
                                    })
                                    .map((_permission) => {
                                       return (
                                          <PermissionCapsule
                                             permission={_permission}
                                             onChange={addToRolePermissions}
                                             key={_permission.id}
                                          />
                                       );
                                    })}
                           </div>

                           {submitError ? (
                              <div className="mb-5">
                                 <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                              </div>
                           ) : null}

                           <div className="mb-3 flex justify-end gap-2">
                              <Button
                                 type="reset"
                                 variant="secondary"
                                 onClick={() => {
                                    controller.abort();
                                    onClose();
                                 }}>
                                 Cancel
                              </Button>
                              <Button variant="primary" type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                 {id ? "Update" : "Create"}
                              </Button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export const PermissionCapsule = ({ permission, onChange, checked = false }) => {
   const checkedBgColorClassName = checked ? "bg-primary text-white" : "bg-gray-200";
   return (
      <>
         <input
            className="appearance-none border-none"
            type="checkbox"
            name={permission.name}
            id={permission.name}
            checked={checked}
            onChange={() => onChange(permission.id)}
         />
         <label
            className={`permission | select-none cursor-pointer min-w-[8rem] flex m-1 px-4 py-2 rounded-lg ${checkedBgColorClassName}`}
            htmlFor={permission.name}>
            {permission.name}
         </label>
      </>
   );
};

export default RoleForm;
