import { endpoints } from "../config/endpoints";
import { axiosPrivateFetch } from "./fetch-service";

const getDashboard = (dashboardView: "global" | "branch"): Promise<any> => {
   return axiosPrivateFetch.get(endpoints.dashboard + "/" + dashboardView);
};

const dashboardService = { getDashboard };

export default dashboardService;
