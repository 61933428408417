const validateBranchCode = (branchCode = "", { isSystemBranch } = { isSystemBranch: false }) => {
   const error = [];

   if (!branchCode) {
      error.push("Branch code is required");
   } else {
      if (isSystemBranch && !/^H\d{2}$/.test(branchCode)) {
         error.push("Branch code not allowed. Branch code should be of format H<DD> - for SYSTEM branch.");
      } else if (!isSystemBranch && (!/^\d{2}$/.test(branchCode) || branchCode === "00")) {
         error.push("Branch code not allowed. Branch code should be of format DD.");
      }
   }

   return error.join(" ");
};

export { validateBranchCode };
