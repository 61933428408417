import { BiPencil } from "react-icons/bi";
import { PaddedIcon } from "../../components/ui/button/Icon";
import Workspace from "../../components/workspace";

const Offering = ({ offering, formModal, setSelected }) => {
   return (
      <Workspace.ItemWrapper
         onClick={() => {
            setSelected(offering);
            formModal.onOpen();
         }}>
         <div className="flex justify-between items-center px-4 py-5">
            <input type="checkbox" className="me-2 md:me-4" onClick={(e) => e.stopPropagation()} />
            <div className="mx-2 w-full overflow-hidden overflow-ellipsis">
               <p className="font-bold">{`${offering.offeringDate} | ${offering.offeringType}`}</p>
               <small className="">{offering.sourceCode}</small> | <small className=" col-1">{offering.currency}</small>
            </div>

            <small className="w-2/12 text-xs">{offering.amount}</small>
            <p className="w-1/12 text-xs mx-1">{offering.branchCode}</p>
            <p className="w-3/12 md:w-2/12">
               {offering.giverFirstName ? `${offering.giverFirstName} ${offering.giverLastName}` : null}
            </p>

            <Workspace.ItemActionsWrapper>
               <PaddedIcon variant="primary">
                  <BiPencil className=" text-xl" />
               </PaddedIcon>
            </Workspace.ItemActionsWrapper>
         </div>
      </Workspace.ItemWrapper>
   );
};

export default Offering;
