import { ChakraProvider } from "@chakra-ui/react";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import ErrorBoundary from "./components/ErrorBoundary";
import RequireAuth from "./components/RequireAuth";
import { Loading } from "./components/ui/loading";
import PageNotFound from "./pages/PageNotFound";
import PermissionManagementPage from "./pages/access-management/permission/PermissionManagement";
import RoleManagementPage from "./pages/access-management/roles/RoleManagement";
import UserManagementPage from "./pages/access-management/users";
import { AttendanceManagement } from "./pages/attendance";
import Unauthorized from "./pages/auth/Unauthorized";
import BranchManagementPage from "./pages/branches";
import Dashboard from "./pages/dashboard";
import LiveEvents from "./pages/live-questions/LiveEvents";
import LiveEventsQuestions from "./pages/live-questions/LiveEventsQuestions";
import GroupTypeManagement from "./pages/membership/group-types";
import GroupManagement from "./pages/membership/groups";
import MemberManagementPage from "./pages/membership/members";
import OfferingManagementPage from "./pages/offering";
import ServiceManagementPage from "./pages/service";
import SettingsLayout from "./pages/settings";
import Profile from "./pages/settings/Profile";
import Security from "./pages/settings/Security";
import SMSManagementPage from "./pages/sms";
import { BranchListStateProvider } from "./state/branchListContext";

// TODO: Use suspense wrapper
const AudioManagement = lazy(() => import("./pages/audios/AudioManagement"));

function SuspenseWrapper({ children }) {
   return <Suspense fallback={<Loading />}>{children}</Suspense>;
}

const routeMap = [
   {
      path: "attendance-management",
      element: <AttendanceManagement />,
   },
   {
      path: "audio-management",
      element: <AudioManagement />,
   },
   {
      path: "groups",
      element: <GroupManagement />,
   },

   {
      path: "group-types",
      element: <GroupTypeManagement />,
   },
   {
      path: "members",
      element: <MemberManagementPage />,
   },
   {
      path: "permissions",
      element: <PermissionManagementPage />,
   },
   {
      path: "roles",
      element: <RoleManagementPage />,
   },
   {
      path: "users",
      element: <UserManagementPage />,
   },
   {
      path: "sms-management",
      element: <SMSManagementPage />,
   },
   {
      path: "offerings",
      element: <OfferingManagementPage />,
   },
   {
      path: "branches",
      element: <BranchManagementPage />,
   },
   {
      path: "live-events",
      element: <LiveEvents />,
   },
   {
      path: "live-events/:eventAlias",
      element: <LiveEventsQuestions />,
   },
   {
      path: "service-management",
      element: <ServiceManagementPage />,
   },
];

export default function App() {
   return (
      <ChakraProvider>
         <ErrorBoundary>
            <SuspenseWrapper>
               <BranchListStateProvider>
                  <Routes>
                     <Route element={<RequireAuth />}>
                        <Route path="/" element={<MainLayout />}>
                           <Route index element={<Navigate to="/dashboard" replace />} />
                           <Route path="dashboard" element={<Dashboard />} />
                           <Route path="dashboard/:dashboardView" element={<Dashboard />} />

                           {routeMap.map((_route) => (
                              <Route key={_route.path} path={_route.path} element={_route.element} />
                           ))}

                           <Route path="settings" element={<SettingsLayout />}>
                              <Route index element={<Navigate to="profile" replace />} />
                              <Route path="profile" element={<Profile />} />
                              <Route path="security" element={<Security />} />
                           </Route>

                           <Route path="unauthorized" element={<Unauthorized />} />
                           <Route path="*" element={<PageNotFound />} />
                        </Route>
                     </Route>
                  </Routes>
               </BranchListStateProvider>
            </SuspenseWrapper>
         </ErrorBoundary>
      </ChakraProvider>
   );
}
