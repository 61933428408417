import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button/Button";
import { InfoTooltip, SelectInput, TextInput } from "../../../components/ui/form";
import Workspace from "../../../components/workspace";
import validateGroupType from "./group-type-validator";
import groupTypeService from "./group.service";

const GroupTypeForm = ({ modal, id, setSelected }) => {
   const [groupType, setGroupType] = useState({
      name: "",
      dedupeRequired: "N",
      isSystemMaintained: "N",
      description: "",
   });
   const [isLoading, setIsLoading] = useState(id);
   const [successNotificationText, setSuccessNotificationText] = useState("");
   const [fetchError, setFetchError] = useState("");
   const [submitError, setSubmitError] = useState();

   const heading = id ? "Edit Group Type" : "Add New Group Type";
   const controller = new AbortController();
   const signal = controller.signal;

   useEffect(() => {
      const loadGroupTypeDetails = async () => {
         try {
            const fetchResult = await groupTypeService.getGroupType(id);
            setGroupType(fetchResult.data);
         } catch (error) {
            setFetchError(error.message);
         } finally {
            setIsLoading(false);
         }
      };
      id && loadGroupTypeDetails();
   }, [id]);

   const handleSubmit = async (groupType) => {
      setSubmitError(null);

      try {
         const submitResult = await groupTypeService.submitGroupTypeMaintenance({
            groupType,
            editOrCreate: id ? "edit" : "create",
         });

         setSuccessNotificationText(submitResult.message);
      } catch (error) {
         setSubmitError(error.message);
      }
   };

   const reset = () => {
      setSuccessNotificationText(null);
      setGroupType({
         name: "",
      });
      setSubmitError(null);
      setSelected && setSelected({});
   };

   if (isLoading) return <Workspace.LoadingModal controller={controller} heading={heading} modal={modal} />;
   if (fetchError)
      return <Workspace.FetchErrorModal modal={modal} heading={heading} errorMessage={fetchError} callback={reset} />;
   if (successNotificationText)
      return <Workspace.SuccessNotificationModal modal={modal} message={successNotificationText} callback={reset} />;

   const resetOnClose = () => {
      reset();
      modal.onClose();
   };

   return (
      <Modal isOpen={modal.isOpen} onClose={resetOnClose} size="2xl">
         <ModalOverlay />
         <ModalContent className="!rounded-2xl">
            <Workspace.ModalHeader>
               <Workspace.ModalHeaderWithCloseButton>{heading}</Workspace.ModalHeaderWithCloseButton>
            </Workspace.ModalHeader>
            <ModalBody className="grid">
               <div className="py-5 px-4">
                  <div className="flex flex-wrap items-center">
                     <div className="w-full">
                        <Formik initialValues={groupType} validate={validateGroupType} onSubmit={handleSubmit} enableReinitialize>
                           {({ isSubmitting }) => (
                              <Form className="grid gap-6">
                                 <TextInput
                                    type="text"
                                    label="Group Type Name"
                                    id="name"
                                    name="name"
                                    placeholder="Enter group type name here"
                                    required
                                 />
                                 <TextInput
                                    type="text"
                                    label="Description"
                                    id="description"
                                    name="description"
                                    placeholder="Enter group type description here"
                                    required
                                 />
                                 <SelectInput
                                    label={
                                       <div className="flex gap-x-1 items-center">
                                          Dedupe Required{" "}
                                          <InfoTooltip>
                                             If yes is selected, members can not be in more than one group of this type.
                                          </InfoTooltip>
                                       </div>
                                    }
                                    id="dedupeRequired"
                                    name="dedupeRequired"
                                    required>
                                    <option value="">Select duplication check</option>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                 </SelectInput>
                                 <SelectInput
                                    label="System Maintained"
                                    id="isSystemMaintained"
                                    name="isSystemMaintained"
                                    required>
                                    <option value="">Select is system maintained</option>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                 </SelectInput>

                                 {submitError && (
                                    <div className="mb-5">
                                       <Workspace.FormSubmitError>{submitError}</Workspace.FormSubmitError>
                                    </div>
                                 )}

                                 <div className="mb-3 flex justify-end gap-2">
                                    <Button
                                       type="reset"
                                       variant="secondary"
                                       onClick={() => {
                                          controller.abort();
                                          resetOnClose();
                                       }}>
                                       Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                       {!Boolean(id) ? "Create group type" : "Edit group type"}
                                    </Button>
                                 </div>
                              </Form>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
};

export { GroupTypeForm };
