import { Offering, OfferingValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import validateAmount from "./validators/validateAmount";
import { validateBranchCode } from "./validators/validateBranchCode";
import validateCurrency from "./validators/validateCurrency";
import validateDate from "./validators/validateDate";
import validateOfferingType from "./validators/validateOfferingType";

export default function validateOffering(offering: Offering) {
   let errors = {} as OfferingValidationError;
   errors.branchCode = validateBranchCode(offering.branchCode);
   errors.offeringType = validateOfferingType(offering.offeringType);
   errors.currency = validateCurrency(offering.currency);
   errors.offeringDate = validateDate(offering.offeringDate);
   errors.amount = validateAmount(offering.amount);

   return stripEmptyObjectProp(errors);
}
