import { endpoints } from "../config/endpoints";
import { axiosFetch } from "./fetch-service";
import tokenService from "./token-service";

function login({ email, password }) {
   return axiosFetch.post(endpoints.login, { email, password });
}

function logout() {
   tokenService.removeAccessToken();
   return axiosFetch.post(endpoints.logout);
}

function getTokenPayload(token: string) {
   if (!token) return;
   const encodedPayload = token.split(".")[1];

   return encodedPayload ? JSON.parse(window.atob(encodedPayload)) : {};
}

function getUserInfo() {
   const accessToken = tokenService.getAccessToken();
   return getTokenPayload(accessToken)?.user || {};
}

function isLoggedIn() {
   const accessToken = tokenService.getAccessToken();

   return getTokenPayload(accessToken)?.exp * 1000 > Date.now() || false;
}

const setRequestBranchAsCookie = (branchCode: string) => {
   let newRequestBranchCookieString =
      process.env.NODE_ENV === "production"
         ? `requestBranch=${branchCode}; domain=.theharvestword.org`
         : `requestBranch=${branchCode}; domain=localhost`;

   newRequestBranchCookieString += "; path=/";

   document.cookie = newRequestBranchCookieString;
};

const getRequestBranchFromCookie = () => {
   return document.cookie
      .split("; ")
      .find((row) => row.startsWith("requestBranch="))
      ?.split("=")[1];
};

const authService = {
   login,
   logout,
   getUserInfo,
   isLoggedIn,
   setRequestBranchAsCookie,
   getRequestBranchFromCookie,
};

export default authService;
