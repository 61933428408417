import "../../styles/sidebar.css";
import { Branch } from "../../types";
import { sortRecordsByObjectProp } from "../../utils/stripEmptyObjectProp";
import { FieldLabel } from "../ui/form";

type SidebarBranchProps = {
   requestBranch: string;
   branches: Branch[];
   changeRequestBranch: (value: string) => void;
};

const SidebarBranch = ({ requestBranch, changeRequestBranch, branches }: SidebarBranchProps) => {
   return (
      <div className="bg-gray-100 py-3 px-4 rounded-2xl">
         <FieldLabel htmlFor="branchCode">Switch branch</FieldLabel>
         <select
            name="branchCode"
            className="form-select | border border-[#ced4da] rounded-lg overflow-hidden text-[0.8rem] w-full px-2 py-[0.3rem]"
            value={requestBranch || ""}
            onChange={(e) => {
               changeRequestBranch(e.target.value);
            }}>
            {sortRecordsByObjectProp(branches, "branchCode").map((_branch, key) => (
               <option value={_branch.branchCode} key={key}>
                  {`${_branch.branchCode} - ${_branch.branchName}`}
               </option>
            ))}
         </select>
      </div>
   );
};

export { SidebarBranch };
