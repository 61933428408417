import { roleMap } from "../config/roleMapping";
import authService from "../services/auth-service";

const ComponentRequireRole = ({ requiredRoles, children }: { requiredRoles: Array<string>; children: React.ReactElement }) => {
   const { getUserInfo } = authService;

   // Senior pastor, HQ resident pastors have global access
   requiredRoles.push(roleMap.seniorPastor, roleMap.HQResidentPastor);

   const userRole = getUserInfo()?.roleId;

   const userHasRequiredRole = requiredRoles?.includes(userRole);

   return userHasRequiredRole ? children : null;
};

const RequirementGuard = ({ condition, children }) => {
   return condition ? children : null;
};

export { ComponentRequireRole, RequirementGuard };
