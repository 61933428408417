import axios from "axios";
import { endpoints } from "../config/endpoints";
import { ApiResponse } from "../types";
import authService from "./auth-service";
import tokenService from "./token-service";

const axiosFetch = axios.create({
   headers: {
      "Content-Type": "application/json",
   },
   withCredentials: true,
});

axiosFetch.interceptors.response.use(
   (response) => response.data,
   (error) => {
      return Promise.reject(new Error(error?.response?.data?.message));
   }
);

const axiosPrivateFetch = axios.create({
   headers: {
      "Content-Type": "application/json",
   },
   withCredentials: true,
});

// Before making request, do the following
axiosPrivateFetch.interceptors.request.use(
   (config) => {
      const token = tokenService.getAccessToken();
      if (token) {
         config.headers["x-csrf-token"] = token;
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

// With response data, do the following
axiosPrivateFetch.interceptors.response.use(
   (response) => response.data as any as ApiResponse,
   async (error) => {
      const isInternalServerError = error.response?.status === 500;

      if (isInternalServerError) {
         return Promise.reject(new Error("Oops! Internal server error."));
      }

      const originalConfig = error.config;

      const errorStatusCode = error?.response?.status;
      const unauthorized = error?.response?.status === 401;

      if (unauthorized) {
         await authService.logout();
         return window.location.replace("/login");
      }

      const accessTokenExpired = errorStatusCode === 403;
      if (accessTokenExpired && !originalConfig._retry) {
         originalConfig._retry = true;

         try {
            const rs = await axiosFetch.post(endpoints.refreshToken, {}, { withCredentials: true });
            const newAccessToken = rs.data?.accessToken;

            if (!newAccessToken) throw new Error("Failed to authenticate");

            tokenService.setAccessToken(newAccessToken);

            return axiosPrivateFetch(originalConfig);
         } catch (_error) {
            return Promise.reject(_error);
         }
      }

      const errorMessage = error.response?.data?.message || "";
      let parsedErrorMessage = "";
      if (Array.isArray(errorMessage)) {
         const errorLength = errorMessage.length;

         for (let i = 0; i < errorLength; i++) {
            parsedErrorMessage = parsedErrorMessage + errorMessage[i] + "\n";
         }
      } else {
         parsedErrorMessage = errorMessage;
      }

      // refresh token expired
      return Promise.reject(parsedErrorMessage ? new Error(parsedErrorMessage) : new Error("Oops! Something went wrong!!"));
   }
);

export { axiosFetch, axiosPrivateFetch };
