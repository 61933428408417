import { Service, ServiceValidationError } from "../types";
import stripEmptyObjectProp from "./stripEmptyObjectProp";
import { validateBranchCode } from "./validators/validateBranchCode";
import validateDate from "./validators/validateDate";
import validateEntityName from "./validators/validateEntityName";
import validateTitle from "./validators/validateTitle";

export default function validateService(service: Service) {
   let errors = {} as ServiceValidationError;

   errors.branchCode = validateBranchCode(service.branchCode);
   errors.serviceDate = validateDate(service.serviceDate, { optional: false });
   errors.name = validateEntityName(service.name, "Service name");
   errors.sermonTitle = validateTitle(service.sermonTitle);

   return stripEmptyObjectProp(errors);
}
